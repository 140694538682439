/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { use404PageData } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Hero, AboveFooter } from "@organisms";

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  const [{ layout, translation }, dispatch] = useAppState();
  const { lang } = translation;

  const data = use404PageData();
  const { hero, cards } = data[lang || "en"];

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const xCraftLivePreview = urlParams.get("x-craft-live-preview");
    const xCraftPreview = urlParams.get("x-craft-preview");

    // set preview mode if preview data is detected
    if (xCraftLivePreview || xCraftPreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    switch (firstSegment) {
      // TODO: Handle pages / issues from list built using static query of all pages and resolve from slug
      case "receivers":
        return <DynamicQueries.ReceiversQuery {...props} />;
      case "teratrak":
        return <DynamicQueries.TeratrakQuery {...props} />;
      case "case-studies":
        return <DynamicQueries.CaseStudiesQuery {...props} />;
      case "events":
        return <DynamicQueries.EventsQuery {...props} />;
      case "displays":
        return <DynamicQueries.DisplaysQuery {...props} />;
      case "transmitters":
        return <DynamicQueries.TransmittersQuery {...props} />;
      default:
        return <DynamicQueries.LandingPagesQuery {...props} />;
    }
  }

  return (
    <PageContainer pageContext={{ language: lang }}>
      <Hero {...hero} tall />
      <AboveFooter cards={cards} />
    </PageContainer>
  );
};

export default NotFoundPage;
